import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../context/store";
import { useLocation } from "react-router-dom";
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
  TbWorld,
} from "react-icons/tb";
import { FaBoxOpen } from "react-icons/fa6";
import { FaExchangeAlt } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaShieldAlt } from "react-icons/fa";
import { BsFileTextFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { FaPeopleCarry } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import { BsBoundingBoxCircles } from "react-icons/bs";
import { setIsSidebarCollapsed } from "../../context/reducers/layout";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import QuickOptions from "./mainHeader/sidebar/QuickOptions";
import SelectedBusiness from "./SelectedBusiness";
import CurrentPlan from "./mainHeader/sidebar/CurrentPlan";
import ImprovePremium from "./mainHeader/sidebar/ImprovePremium";
import OptionItem from "./mainHeader/sidebar/OptionItem";
import PolicyOption from "./sidebar/PolicyOption";
import Community from "./mainHeader/sidebar/Community";
import DownloadApp from "./mainHeader/sidebar/DownloadApp";

export default function Sidebar() {
  const isCollapsed = useSelector(
    (state: RootState) => state.layout.isSidebarCollapsed
  );
  const collaboration = useSelector(
    (state: RootState) => state.layout.collaboration
  );
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const currentPlan = useSelector(
    (state: RootState) => state.layout.currentPlan
  );
  const [currentPathname, setCurrentPathname] = useState<string>("");
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentPathname(location?.pathname);
  }, [location]);

  const getIconColor = (pathname: string): string => {
    return currentPathname == pathname ? "#00a680" : "#454545";
  };

  const list = [
    {
      title: "Tienda online",
      icon: <TbWorld style={{ color: getIconColor("/online-store") }} />,
      path: "/online-store",
      search: "?tab=STORE_DATA",
    },
    {
      title: "Inventario",
      icon: <FaBoxOpen style={{ color: getIconColor("/inventory") }} />,
      path: "/inventory",
      search: "?tab=GENERAL",
    },
    {
      title: "Transacciones",
      icon: <FaExchangeAlt style={{ color: getIconColor("/transactions") }} />,
      path: "/transactions",
      search: "?type=SALES",
    },
    {
      title: "Estadísticas",
      icon: <BsGraphUpArrow style={{ color: getIconColor("/statistics") }} />,
      path: "/statistics",
    },
    {
      title: "Clientes o contactos de cobro",
      icon: <FaUsers style={{ color: getIconColor("/clients") }} />,
      path: "/clients",
    },
    {
      title: "Proveedores o contactos de pago",
      icon: <FaPeopleCarry style={{ color: getIconColor("/providers") }} />,
      path: "/providers",
    },
    {
      title: "Asociarse a un negocio",
      icon: (
        <BsBoundingBoxCircles
          style={{ color: getIconColor("/join-a-business") }}
        />
      ),
      path: "/join-a-business",
    },
    {
      type: "COMPONENT",
      component: <Community />,
    },
    {
      title: "Redes sociales",
      type: "SECTION",
      subtitle:
        "🚀 En nuestras redes sociales subimos videos/tutoriales a diario sobre cómo utilizar Ganvent para sacarle el máximo provecho y puedas llevar tu negocio/emprendimiento al siguiente nivel. Además, también subimos tips sobre cómo vender más, cómo mejorar tu tienda virtual, etc. ¡Síguenos, no te arrepentirás!🚀",
      options: [
        {
          icon: <FaInstagram style={{ color: "white" }} />,
          title: "Instagram",
          url: "https://www.instagram.com/ganvent.pe/",
          color: "#C13584",
        },
        {
          icon: <FaTiktok style={{ color: "white" }} />,
          title: "Tiktok",
          url: "https://www.tiktok.com/@ganventapp?lang=es",
          color: "black",
        },
        {
          icon: <FaYoutube style={{ color: "white" }} />,
          title: "Youtube",
          url: "https://www.youtube.com/channel/UC9TES2cHy7KP2EfcDUhkZEw/featured",
          color: "red",
        },
        {
          icon: <FaFacebook style={{ color: "white" }} />,
          title: "Facebook",
          url: "https://www.facebook.com/ganventapp/",
          color: "#4267B2",
        },
        {
          icon: <BiLogoGmail style={{ color: "white" }} />,
          title: "Correo",
          url: "mailto:soporte@ganvent.shop",
          color: "#c71610",
        },
      ],
    },
  ];

  const handleClickCollapseButton = () => {
    dispatch(
      setIsSidebarCollapsed({
        isSidebarCollapsed: true,
      })
    );
  };

  return (
    <Container>
      <Header
        style={{
          marginRight: !isCollapsed ? "12px" : "0px",
          marginLeft: !isCollapsed ? "12px" : "0px",
          justifyContent: !isCollapsed ? "flex-start" : "center",
        }}
      >
        <LogoContainer>
          <Logo src={require("../../images/ganventLogo.png")} />
        </LogoContainer>
        {!isCollapsed && <Ganvent>Ganvent</Ganvent>}
        {!isCollapsed && (
          <IconButton onClick={handleClickCollapseButton}>
            <TbLayoutSidebarLeftCollapse />
          </IconButton>
        )}
      </Header>
      {isCollapsed && (
        <StyledIconButton
          onClick={() => {
            dispatch(
              setIsSidebarCollapsed({
                isSidebarCollapsed: false,
              })
            );
          }}
        >
          <TbLayoutSidebarRightCollapse />
        </StyledIconButton>
      )}
      <SelectedBusiness />
      <CurrentPlan />
      <ImprovePremium />
      <QuickOptions />
      <DownloadApp />
      <List>
        {list.map((item, index) => (
          <OptionItem key={index} item={item} />
        ))}
      </List>
      {!isCollapsed && <div style={{ marginTop: "20px" }} />}
      {!isCollapsed && (
        <>
          <PolicyOption
            link="https://ganvent.shop/politica-de-privacidad"
            title="Política de Privacidad"
            icon={<FaShieldAlt />}
          />
          <PolicyOption
            link="https://ganvent.shop/terminos-y-condiciones"
            title="Términos y condiciones"
            icon={<BsFileTextFill />}
          />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 50px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 12px;
  margin-right: 12px;
`;

const LogoContainer = styled.div`
  height: 50px;
  width: 50px;
`;

const Logo = styled.img`
  height: 100%;
  width: 100%;
`;

const Ganvent = styled.p`
  flex: 1;
  margin-left: 7px;
  font-size: 1.2rem;
  font-weight: bold;
`;

const List = styled.div`
  margin-top: 10px;
`;

const StyledIconButton = styled(IconButton)`
  width: 100%;
  height: 60px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &&& {
    border-radius: 0px;
  }
`;
