import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../context/store";
import styled from "styled-components";
import { ChevronRight } from "@mui/icons-material";
import playstoreLogo from "../../../public/images/playstoreLogo.png";
import appstoreLogo from "../../../public/images/appstoreLogo.png";

interface ContainerI {
  isCollapsed: boolean;
}

export default function DownloadApp() {
  const isCollapsed = useSelector(
    (state: RootState) => state.layout.isSidebarCollapsed
  );

  const handleClick = () => {};

  const navigateTo = (url: string) => {
    window.open(url, "_blank");
  };

  if (isCollapsed) return <></>;

  return (
    <Container isCollapsed={isCollapsed} onClick={handleClick}>
      {!isCollapsed && (
        <>
          <Text>Descarga Ganvent en tu celular</Text>
          <Content>
            <ImageContainer
              onClick={() =>
                navigateTo(
                  "https://play.google.com/store/apps/details?id=com.olivaresventuraricardo.minventapp"
                )
              }
            >
              <Image src={require("../../../../images/playstoreLogo.png")} />
            </ImageContainer>
            <ImageContainer
              onClick={() =>
                navigateTo(
                  "https://apps.apple.com/pe/app/ganvent-crea-tu-tienda-online/id1664995370"
                )
              }
            >
              <Image src={require("../../../../images/appstoreLogo.png")} />
            </ImageContainer>
          </Content>
        </>
      )}
    </Container>
  );
}

const Container = styled.div<ContainerI>`
  border: 1px solid #00a680;
  border-radius: 8px;
  padding-left: ${(props) => (!props.isCollapsed ? "12px" : "0px")};
  padding-right: ${(props) => (!props.isCollapsed ? "8px" : "0px")};
  /*   display: flex;
  flex-direction: row;
  align-items: center; */
  justify-content: ${(props) => (!props.isCollapsed ? "flex-start" : "center")};
  background-color: white;
  margin-left: 15px;
  margin-right: ${(props) => (!props.isCollapsed ? "8px" : "15px")};
  margin-top: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const IconContainer = styled.div`
  position: absolute;
  top: -10px;
  right: -5px;
`;

const Information = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const Label = styled.p`
  font-size: 10px;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 700;
  color: #00a680;
`;

const Title = styled.p`
  font-size: 10px;
`;

const Text = styled.p`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 190px;
  height: 70px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
